import React from 'react'

const Footer = () => {

  return (
    <div>
      {/* This is the footer */}
    </div>
  )

}

export default Footer
