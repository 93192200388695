import styled from 'styled-components'

export const HeaderView = styled.header`
  display: block;
  text-align: center;
  padding: 5vh 20px 30px;
`

export const LogoImg = styled.img`
  max-width: 100%;
  width: 250px;
`
