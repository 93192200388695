import React from 'react'
import Helmet from 'react-helmet'

import GlobalStyle from '../../styleUtils/GlobalStyle'
import Header from '../Header'
import Footer from '../Footer'
import {
  MainView
} from './style'

const Main = ({ children }) => {

  // og: title and desc are covered by gatsby config

  return (
    <>
      <Helmet>
        <meta property="og:image" content="https://www.loganhigh2000.com/social-image-wide.jpg" />
        <link href="https://fonts.googleapis.com/css?family=Nunito:400,400i,700&display=swap" rel="stylesheet" />
      </Helmet>
      <GlobalStyle />
      <Header />
      <MainView>
        {children}
      </MainView>
      <Footer />
    </>
  )

}


export default Main
