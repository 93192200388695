import React from 'react'

import LHSLogo from '../../images/LHS-logo.png'
import {
  HeaderView,
  LogoImg
} from './style'

const Header = () => {

  return (
    <HeaderView>
      <LogoImg src={LHSLogo} alt="LHS Logo" />
    </HeaderView>
  )

}

export default Header
