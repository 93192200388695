import { createGlobalStyle } from 'styled-components'
import {getColor, getFont, getWeight} from './index'
import normalize from './normalize'

// Fonts are loaded in a separate static css file, because: https://github.com/styled-components/styled-components/issues/1593
const GlobalStyles = createGlobalStyle`

  ${normalize}

  *{
    box-sizing: border-box;
  }

  *:before,
  *:after {
    box-sizing: border-box;
  }


  html {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-size: 16px;
  }

  body {
    font-family: ${getFont('primary')};
    font-weight: ${getWeight('normal')};
    color: ${getColor('black')};
    background-color: ${getColor('white')};
    font-size: 16px;
    line-height: 1.2em;
  }

  input,
  button,
  select,
  textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  a {
    color: ${getColor('blue')};
    text-decoration: none;

    &:hover,
    &:focus {
      color: ${getColor('blue')};
      text-decoration: none;
    }

    &:focus {
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }
  }

  figure {
    margin: 0;
  }

  img {
    vertical-align: middle;
  }

  hr {
    margin-top:    10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid ${getColor('gray')};
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }

  .sr-only-focusable {
    &:active,
    &:focus {
      position: static;
      width: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      clip: auto;
    }
  }

  [role="button"] {
    cursor: pointer;
  }

  h1, h2, h3, h4, h5, h6{
    margin: 20px 0 15px;
    line-height: 1.2em;
  }

  p{
    margin: 0 0 10px;
  }

  ul,
  ol {
    margin-top: 0;
    margin-bottom: 10px;
    ul,
    ol {
      margin-bottom: 0;
    }
  }

  table {
    background-color: transparent;
  }
  th {
    text-align: left;
  }
`

export default GlobalStyles
