import {css} from 'styled-components'

const screenSizes = {
  xLarge: 1400,
  large: 1200,
  medium: 992,
  small: 768,
  xSmall: 480
}

const colors = {
  black: {
    base: '#231F20',
  },
  white: {
    base: '#ffffff',
  },
  gray: {
    base: '#7A6C6C',
    light: '#CFC3C3',
  },
  green: {
    base: '#487629',
  },
  blue: {
    base: '#00609C',
  },
  gold: {
    base: '#d0912a',
  },
  crimson: {
    base: '#981b1e',
    light: '#d81b1f'
  }
}

const fonts = {
  primary: 'Nunito, sans-serif',
}

const fontWeights = {
  normal: 400,
  bold: 700
}

/**
 * Iterate through sizes and create a media query (see screenSizes)
 * @example ${media.small`
 *   color: red;
 * `}
 */
export const media = Object.keys(screenSizes).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = screenSizes[label] / 16
  accumulator[label] = (...args) => css`
    @media (min-width: ${emSize}em) {
      ${css(...args)}
    }
  `
  return accumulator
}, {})

export default {
  media,
  getColor
}

/**
 * Get a color hex value
 * @param  {string} color     the color group
 * @param  {string} variation the variation if any
 * @return {string}           The chosen color (defaults to white if not found)
 * @example ${getColor('blue')} //returns the blue base color
 * @example ${getColor('blue', 'dark')} //returns the dark variation of blue
 */
export function getColor(color, variation){
  const colorGroup = colors[color]
  return variation ? colorGroup[variation] : colorGroup.base || '#FFFFFF'
}

/**
 * Gets a font family
 * @example ${getFont('primary')}
 */
export function getFont(family){
  return fonts[family]
}

/**
 * Gets a weight value given a string name
 * @param  {string} weight normal, light, bold
 * @return {number}        the number weight
 * @example ${getWeight('bold')}
 */
export function getWeight(weight){
  return fontWeights[weight]
}
